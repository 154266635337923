import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { rtlLangs } from '../../translations/rtl-langs';

export class Airline extends Component {
  render() {
    const { currentSeatmap, flights = [], locale } = this.props;
    const [currentFlight = {}] = flights.filter(flight => {
      return flight.segment_id === currentSeatmap;
    });

    return (
      <div
        className="gr-hidden md:gr-flex sm:p-6 gr-text-sm"
        style={{ minWidth: 115 }} // Prevent text from wrapping to more 3 lines
      >
        <img
          className="gr-h-12 gr-w-12 gr-rounded-full"
          alt={currentFlight.operating_airline}
          aria-label={
            currentFlight.operating_airline +
            ' ' +
            currentFlight.operating_flight_number
          }
          src={currentFlight.operating_airline_logo}
        />
        <div className="gr-block gr-text-left gr-ml-2 gr-mt-1">
          <h2 className="gr-text-sm gr-text-title">
            {currentFlight.operating_airline}
            {currentFlight.operating_flight_number}
          </h2>
          <div
            className="operated_by gr-text-secondary-text gr-text-sm"
            dir={rtlLangs.includes(locale) ? 'rtl' : 'ltr'}
          >
            <Translate id="operated_by" />: {currentFlight.operating_airline}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  flights: state.itinerary.flights,
  currentSeatmap: state.itinerary.currentSeatmap,
  locale: state.session.locale
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Airline);
