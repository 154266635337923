import React from 'react';

import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';

import MoonLoader from 'react-spinners/ClipLoader';
import SeatRecline from '../shared/SeatRecline';
import { showModal, setActiveWidget } from '../../redux/actions';
import styled from 'styled-components';
import { getSeatTriggerSubtitle } from '../../utils/trigger';
import { Translate } from 'react-localize-redux';

const SeatMapTrigger = styled.section`
  margin: 20px;
  background: #ffffff;
  padding: 25px;
  font-family: Roboto;
  border: 2px solid #e1e8ee;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 18px;
  display: flex;
`;

const Button = styled.button`
  width: 200px;
  height: 50px;
  color: white;
  background: #2990e6;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
`;

const Trigger = ({
  seats,
  flights,
  showModal,
  hasSeats,
  setActiveWidget,
  textOverrides,
}) => {
  const triggerSubtitle = getSeatTriggerSubtitle(hasSeats, textOverrides);
  const subtitle = triggerSubtitle.includes('seat_load_') ? (
    <Translate id={triggerSubtitle} />
  ) : (
    triggerSubtitle
  );

  return (
    <SeatMapTrigger className="gr-block sm:gr-flex">
      <div className="g-flex-1">
        <div className="gr-flex">
          <SeatRecline />
          <div>
            <h2 className="gr-m-0 gr-ml-4 gr-text-primary">
              <Translate id="seat_selection" />
            </h2>
            {seats.length > 0 ? (
              seats.map(({ name = '', passenger_id = '' }) => (
                <p className="gr-mx-4 gr-text-gray-600" key={uuidv4()}>
                  {passenger_id.toUpperCase()} {name}
                </p>
              ))
            ) : (
              <p className="gr-mx-4 gr-text-gray-600">{subtitle}</p>
            )}
          </div>
        </div>
      </div>
      <div
        id="gordian-select-seat-button-container"
        className="gordian-card-button-container gr-flex-1 sm:gr-justify-end gr-flex gr-items-center"
      >
        {hasSeats ? (
          <Button
            disabled={!(flights.length > 0)}
            onClick={() => {
              showModal(true);
              setActiveWidget('seatmap');
            }}
            id="gordian-seat-selection"
            className="gr-text-center gr-border-0 gr-rounded-lg"
          >
            {flights.length > 0 ? (
              <Translate id="select_seat" />
            ) : (
              <MoonLoader size={25} color={'white'} />
            )}
          </Button>
        ) : null}
      </div>
    </SeatMapTrigger>
  );
};

const mapStateToProps = (state) => ({
  seats: state.basket.seats,
  hasSeats: state.session.hasSeats,
  flights: state.itinerary.flights,
  textOverrides: state.session.textOverrides,
});

const mapDispatchToProps = {
  showModal,
  setActiveWidget,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trigger);
