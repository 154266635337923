import React, { useState } from 'react';
import { connect } from 'react-redux';

import MoonLoader from 'react-spinners/ClipLoader';
import {
  showModal,
  setActiveWidget,
  setBaggageBasket,
  setBaggageData,
} from '../../redux/actions.js';
import styled from 'styled-components';
import BaggageIcon from '../baggage/BaggageIcon';
import { Translate } from 'react-localize-redux';
import { parseAcceptedBags } from '../../utils/parseAcceptedBags';

const BaggageWidgetTrigger = styled.section`
  margin: 20px;
  background: #ffffff;
  padding: 25px;
  font-family: Roboto;
  border: 2px solid #e1e8ee;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 18px;
  display: flex;
`;

const Button = styled.button`
  width: 200px;
  height: 50px;
  color: white;
  background: #2990e6;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
`;

const Trigger = ({
  baggageData,
  showModal,
  setActiveWidget,
  hasBags,
  setBaggageBasket,
  basket,
  variants,
}) => {
  const [removeButtonClicked, setRemoveButtonClicked] = useState(false);
  const availableBags = Boolean(baggageData?.results?.products?.bag);
  const amountOfSelectedBags = Object.values(basket.bags)
    .flatMap((p) => Object.values(p).map((b) => b.quantity))
    .reduce((totalBags, quantity) => totalBags + quantity, 0);

  const showRemoveButton =
    (typeof variants?.test_clear_baggage_basket_button === 'string' &&
      variants?.test_clear_baggage_basket_button.toLowerCase() === 'true') ||
    variants?.test_clear_baggage_basket_button === true;

  return (
    <BaggageWidgetTrigger className="gr-block sm:gr-flex">
      <div className="g-flex-1">
        <div className="gr-flex">
          <BaggageIcon scale={2} />
          <div>
            <h2 className="gr-m-0 gr-ml-4 gr-text-primary">
              <Translate id="baggage.title" />
            </h2>
            <p className="gr-mx-4 gr-text-gray-600">
              {hasBags ? (
                <Translate id="bags_load_success" />
              ) : (
                <Translate id="bags_load_failed" />
              )}
            </p>
            <p className={'gr-ml-4 gr-font-bold gr-text-base gr-text-gray-500'}>
              Total Items: {amountOfSelectedBags}
            </p>
          </div>
        </div>
      </div>
      <div
        id="gordian-bag-select-button-container"
        className="gordian-card-button-container gr-flex-1 sm:gr-justify-end gr-flex gr-items-center gr-overflow-auto"
      >
        {hasBags ? (
          <>
            {showRemoveButton ? (
              <Button
                onClick={() => {
                  setBaggageBasket({ bags: {} });
                  if (window.onBaggageBasketChange) {
                    window.onBaggageBasketChange([]);
                  }
                  setRemoveButtonClicked(true);
                  setTimeout(() => {
                    setRemoveButtonClicked(false);
                  }, 2000);
                }}
                disabled={!availableBags || removeButtonClicked}
                id="gordian-remove-bag-selection"
                className={`${
                  !removeButtonClicked
                    ? 'gr-bg-gray-400 gr-text-black'
                    : 'gr-bg-transparent'
                } gr-text-center gr-border-0 gr-rounded-lg gr-mr-4`}
                style={{
                  ...(removeButtonClicked && {
                    color: '#2990e6',
                  }),
                }}
              >
                {availableBags ? (
                  removeButtonClicked ? (
                    '✓ All Bags Removed'
                  ) : (
                    'Remove All Bags'
                  )
                ) : (
                  <MoonLoader size={25} color={'white'} />
                )}
              </Button>
            ) : null}
            <Button
              onClick={() => {
                showModal(true);
                setActiveWidget('baggage');
              }}
              disabled={!availableBags}
              id="gordian-bag-selection"
              className="gr-text-center gr-border-0 gr-rounded-lg"
            >
              {availableBags ? (
                <Translate id="add_bags" />
              ) : (
                <MoonLoader size={25} color={'white'} />
              )}
            </Button>
          </>
        ) : null}
      </div>
    </BaggageWidgetTrigger>
  );
};

const mapStateToProps = (state) => ({
  flights: state.itinerary.flights,
  baggageData: state.baggageJSON.data,
  hasBags: state.session.hasBags,
  basket: parseAcceptedBags(state.basket, state.baggageJSON.data),
  variants: state.session.variants,
});

const mapDispatchToProps = {
  showModal,
  setActiveWidget,
  setBaggageBasket,
  setBaggageData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trigger);
